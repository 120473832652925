@tailwind base;
@tailwind components;
@tailwind utilities;

 {
  display: block;
  background: aliceblue;
}

.skeleton {
  position: relative;
  background-color: #a8a8a8;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* The moving element */
.skeleton::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);

  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );

  /* Adding animation */
  -webkit-animation: loading 1s infinite;
          animation: loading 1s infinite;
}

/* Loading Animation */
@-webkit-keyframes loading {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@keyframes loading {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --pseudo-element-display: unset !important;
}

.react-loading-skeleton::after {
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    #afacac,
    var(--base-color)
  ) !important;
}

.explore {
  font-family: "Futura-Bold", sans-serif;
  position: relative;
  color: #fff;
  overflow: hidden;
}
.explore .content-explore {
  width: 310px;
  position: relative;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
}
.explore .content-explore .carousel-slide {
  flex: 0 0 100%;
  /* Each slide takes 100% width of the container */
  transition: opacity 1s;
  /* Fade transition effect */
  opacity: 0;
}
.explore .content-explore .fade-in-right {
  animation: fadeInRight 1.5s forwards, fadeOutLeft 1.5s 3s forwards;
  -webkit-animation: fadeInRight 1.5s forwards, fadeOutLeft 1.5s 3s forwards;
}
@-webkit-keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
  }
}
@keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
  }
}
@-webkit-keyframes fadeInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fadeInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
.explore .content-explore .d-none {
  display: none;
}
.explore .content-explore .d-block {
  display: block;
}
.explore .content-explore .text-explore {
  position: absolute;
  width: 100%;
  left: 0;
}
.explore .content-explore .text-explore {
  -webkit-animation-name: fade;
          animation-name: fade;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-direction: normal;
          animation-direction: normal;
}
@-webkit-keyframes fade {
  0% {
    transform: translateX(200px);
    opacity: 0;
    -webkit-transform: translateX(200px);
    -moz-transform: translateX(200px);
    -ms-transform: translateX(200px);
    -o-transform: translateX(200px);
  }
  50% {
    transform: translateX(0);
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
  100% {
    transform: translateX(-200px);
    opacity: 0;
    -webkit-transform: translateX(-200px);
    -moz-transform: translateX(-200px);
    -ms-transform: translateX(-200px);
    -o-transform: translateX(-200px);
  }
}
@keyframes fade {
  0% {
    transform: translateX(200px);
    opacity: 0;
    -webkit-transform: translateX(200px);
    -moz-transform: translateX(200px);
    -ms-transform: translateX(200px);
    -o-transform: translateX(200px);
  }
  50% {
    transform: translateX(0);
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
  100% {
    transform: translateX(-200px);
    opacity: 0;
    -webkit-transform: translateX(-200px);
    -moz-transform: translateX(-200px);
    -ms-transform: translateX(-200px);
    -o-transform: translateX(-200px);
  }
}
.explore .title {
  line-height: 62px;
  font-size: 50px;
  text-align: center;
}
.explore .sub-title {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 22px;
  text-align: center;
  margin-bottom: 40px;
}
.explore .sub-title.quotes {
  font-size: 27px;
}
.explore .sub-title div {
  width: 611px;
}
.explore .main-title {
  line-height: 90px;
  text-align: center;
  font-size: 90px;
  height: 567px;
  position: relative;
  z-index: 20;
}
.explore .main-title span {
  line-height: 30px;
  display: block;
  font-size: 20px;
}
.explore .bg-img {
  height: 100%;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
}
.explore .img-explore {
  position: absolute;
  bottom: -1px;
  width: 420px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

@media (max-width: 767px) {
  .explore {
    overflow: hidden;
    padding-top: 293px;
    position: relative;
    height: 118vh;
  }
  .explore .content-explore .title {
    font-size: 20px !important;
  }
  .explore .content-explore .sub-title.quotes {
    font-size: 16px;
    margin-top: 10px;
  }
  .explore .img-explore {
    width: 50% !important;
  }
  .explore .title {
    line-height: 38px;
    font-size: 30px;
  }
  .explore .main-title {
    font-size: 30px;
    line-height: 30px;
    height: 296px;
  }
  .explore .main-title span {
    font-size: 11px;
  }
  .explore .sub-title.quotes {
    font-size: 17px;
  }
}/*# sourceMappingURL=explore.css.map */
.wrapper-hero {
  position: relative;
}
.wrapper-hero .wrapper-gunung {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.wrapper-hero .hero {
  height: 100vh;
  position: relative;
}
.wrapper-hero .hero .rumput-laut {
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 60%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.wrapper-hero .hero .img-center {
  position: absolute;
  top: 14%;
  width: 100%;
  z-index: 10;
}
.wrapper-hero .hero .kota {
  position: absolute;
  width: 100%;
  z-index: 9;
  top: 50%;
  left: 0;
}
.wrapper-hero .hero .gunung-1 {
  position: absolute;
  z-index: 8;
  width: 100%;
  left: 0;
  top: 56%;
}
.wrapper-hero .hero .gunung-2 {
  position: absolute;
  z-index: 7;
  width: 100%;
  left: 0;
  top: 47%;
}
.wrapper-hero .hero .gunung-3 {
  position: absolute;
  z-index: 6;
  width: 100%;
  left: 0;
  top: 45%;
}
.wrapper-hero .hero .gunung-4 {
  position: absolute;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 35%;
}
.wrapper-hero .hero .particle-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.wrapper-hero .hero .particle-center {
  position: absolute;
  top: 10%;
  left: 50%;
  width: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
}
.wrapper-hero .hero .particle-behind {
  position: absolute;
  top: 30%;
  z-index: 1;
  width: 100%;
}
.wrapper-hero .hero .particle-behind-2 {
  position: absolute;
  top: 30%;
  z-index: 1;
  width: 100%;
}
.wrapper-hero .hero .particle-bottom {
  width: 100%;
  position: absolute;
  top: 115%;
  z-index: 5;
}
.wrapper-hero .hero .gif {
  width: 12%;
  position: absolute;
}
.wrapper-hero .hero .gif-1 {
  bottom: 0%;
  right: 10%;
  width: 14%;
  z-index: 20;
}
.wrapper-hero .hero .gif-2 {
  width: 14%;
  bottom: 9%;
  left: 16%;
  z-index: 20;
}
.wrapper-hero .hero .gif-3 {
  width: 12%;
  bottom: 30%;
  right: 24%;
  z-index: 20;
}
.wrapper-hero .hero .gif-4 {
  width: 10%;
  top: 40%;
  left: 14%;
  z-index: 20;
}
.wrapper-hero .hero .gif-5 {
  bottom: -45%;
  right: 15%;
  z-index: 20;
  width: 18%;
}
.wrapper-hero .hero .gif-6 {
  bottom: -45%;
  left: 15%;
  z-index: 20;
}
.wrapper-hero .bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.wrapper-hero .img-center-mobile {
  position: absolute;
  width: 100%;
  display: none;
}

@media (orientation: portrait) {
  .img-center {
    position: absolute;
    top: 80%;
    width: 100%;
    z-index: 10;
  }
  .wrapper-hero .wrapper-gunung {
    height: 37vh !important;
  }
}
@media (max-width: 767px) {
  .hero .gunung,
  .hero .gif,
  .hero .img-center,
  .hero .particle-bottom,
  .hero .particle-center,
  .hero .particle-behind {
    display: none;
  }
  .hero {
    height: 500px !important;
  }
  .img-center-mobile {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .wrapper-hero .hero {
    height: 70vh;
  }
  .wrapper-hero .wrapper-gunung {
    height: 70vh;
  }
}
@media (min-width: 628px) and (max-width: 768px) {
  .wrapper-hero .hero {
    height: 921px !important;
  }
}
@media only screen and (min-width: 1366px) and (min-height: 1024px) and (orientation: landscape) {
  .wrapper-hero .hero {
    height: 37vh;
  }
}
@media only screen and (min-width: 1024px) and (min-height: 1366px) and (orientation: portrait) {
  .wrapper-hero .hero {
    height: 47vh;
  }
  .wrapper-hero .hero .img-center {
    top: 24%;
  }
  .wrapper-hero .wrapper-gunung {
    height: 46vh !important;
  }
  .wrapper-hero .hero .particle-center {
    top: 20%;
  }
}
@media screen and (min-width: 580px) and (max-width: 653px) and (orientation: landscape) {
  .wrapper-hero .hero {
    height: 77vh;
  }
  .wrapper-hero .wrapper-gunung {
    height: 79vh;
  }
}
@media screen and (min-width: 534px) and (max-width: 653px) and (orientation: portrait) {
  .wrapper-hero .hero {
    height: 703px !important;
  }
  .explore {
    padding: 365px 0px;
    padding-bottom: 225px !important;
  }
  .explore .main-title {
    font-size: 40px;
    line-height: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) and (orientation: landscape) {
  .wrapper-hero .hero {
    height: 84vh !important;
  }
  .wrapper-hero .wrapper-gunung {
    height: 84vh;
  }
  .explore {
    padding: 218px 0px;
    padding-bottom: 300px;
  }
}/*# sourceMappingURL=hero.css.map */
.header .logo {
  width: 140px;
  object-fit: contain;
}
.header .wrapper-logo {
  display: contents;
}
.header .nav-wrapper {
  width: 100%;
  justify-content: center;
}
.header .nav {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 30;
  background: transparent;
  transition: background-color 0.3s ease-in-out;
  position: fixed;
}
.header .nav .list-nav {
  margin: 0px 122px;
}
.header .nav ul {
  margin: 0px;
  bottom: 0px;
  padding: 0px;
}
.header .nav ul li {
  font-family: "Futura-Bold", sans-serif;
  padding: 20px 20px;
  display: inline-block;
  color: #fff;
  font-size: 22px;
}
.header .nav ul li a {
  text-decoration: none;
  color: #fff;
}
.header .nav .sosmed {
  margin: 0px;
  padding: 0px;
  display: none;
}
.header .nav .sosmed img {
  width: 24px;
}
.header .nav .sosmed li {
  padding: 20px 10px;
}
.header .nav .logo {
  display: none;
}
.header .nav.scrolling-active {
  background: rgba(20, 56, 90, 0.41);
}
.header .nav.scrolling-active .sosmed {
  display: block;
}
.header .nav.scrolling-active .logo {
  display: block;
}

.button-close {
  position: absolute;
  top: 50%;
  right: 0%;
  display: none;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.toggle {
  display: none;
}

.nav.nav-collapse {
  background-color: #14385a !important;
  color: #fff;
  padding: 30px 0px;
}
.nav.nav-collapse .toggle {
  display: none;
}
.nav.nav-collapse .sosmed {
  display: block;
}
.nav.nav-collapse .sosmed li {
  margin-right: 10px;
  padding: 20px 0px;
}
.nav.nav-collapse .button-close {
  display: block;
}
.nav.nav-collapse .wrapper-logo {
  display: block;
}
.nav.nav-collapse .wrapper-logo .logo {
  display: block;
}
.nav.nav-collapse .nav-wrapper {
  flex-direction: column;
  justify-content: left;
}
.nav.nav-collapse .nav-wrapper .list-nav {
  display: unset;
  margin: 0px;
  margin-top: 20px;
}
.nav.nav-collapse .nav-wrapper .list-nav li {
  padding: 5px 0px;
  display: block;
  list-style: none;
}

@media (max-width: 992px) {
  .nav.nav-collapse .sosmed {
    display: block !important;
  }
  .header .wrapper-logo,
  .header .sosmed,
  .header .list-nav {
    display: none;
  }
  .header .nav-wrapper {
    padding: 20px 34px;
    justify-content: right;
  }
  .header .nav.scrolling-active .sosmed {
    display: none;
  }
  .header .toggle {
    display: block;
  }
}/*# sourceMappingURL=nav.css.map */
.service {
  position: relative;
  color: #fff;
  padding: 200px 0px;
}
.service.post-page {
  padding: 0px 0px;
  padding-bottom: 30%;
}
.service.post-page .title {
  margin-bottom: 10px;
  text-align: left;
  color: #fff;
}
.service.post-page .side-menu .menu-item {
  margin-bottom: 20px;
  padding: 20px 10px;
  cursor: pointer;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.service.post-page .side-menu .menu-item:hover {
  background-color: #29a7bb;
}
.service.post-page .side-menu .menu-item.active {
  background-color: #29a7bb;
}
.service.post-page .side-menu .menu-item img {
  width: 40px;
}
.service.post-page .side-menu .menu-item .title-menu {
  margin-left: 15px;
  font-size: 20px;
  font-family: "Futura-Medium", sans-serif;
}
.service .content {
  position: relative;
  z-index: 2;
}
.service .title {
  font-family: "Futura-Medium", sans-serif;
  color: #00e6f0;
  text-align: center;
  font-size: 58px;
  margin-bottom: 100px;
}
.service .bg-img {
  top: 0px;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.service .particle {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}
.service .wrapper-item {
  width: 915px;
  display: flex;
  flex-wrap: wrap;
}
.service .item {
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  margin: 0px 65px 82px 65px;
  width: 175px;
  font-size: 32px;
  font-family: "Futura-Bold", sans-serif;
}
.service .item img {
  width: 100%;
}

@media (max-width: 767px) {
  .service {
    padding: 119px 0px;
  }
  .service .title {
    margin-bottom: 20px;
  }
  .service .item {
    margin: 0px 0px 19px 0px;
    font-size: 20px;
    width: unset;
  }
  .service .wrapper-item {
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .wrapper-item {
    justify-content: center;
  }
  .service .item {
    width: 102px;
  }
}
@media screen and (min-width: 601px) and (max-width: 906px) and (orientation: portrait) {
  .service .wrapper-item {
    flex-direction: column;
    text-align: center;
  }
}/*# sourceMappingURL=service.css.map */
.galery {
  height: 100vh;
  background-color: #0e2c4e;
  position: relative;
}
.galery video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.galery img {
  object-fit: cover;
}

@media (max-width: 767px) {
  .galery {
    height: 80vh;
  }
  .galery img {
    width: 100%;
    height: 80vh;
  }
  video {
    width: 100% !important;
  }
}
@media (max-width: 425px) {
  .galery {
    height: 38vh;
    padding: unset;
  }
  .galery img {
    width: 100%;
    height: 38vh;
  }
}/*# sourceMappingURL=galery.css.map */
.experience {
  position: relative;
  color: #fff;
  padding: 66px 0px;
  background-color: #0e2c4e;
  top: 0;
}
.experience .list-activity .line {
  width: 37.5px;
  height: 1px;
  background-color: #fff;
  margin-right: 10px;
}
.experience .list-activity .content-text {
  width: 371px;
}
.experience .list-activity .item-activity {
  color: #fff !important;
  margin-bottom: 15px;
}
.experience .list-activity .item-activity a {
  text-decoration: none;
}
.experience .list-activity .item-activity.item-right img {
  width: 223px;
  height: 153px;
}
.experience .list-activity .item-activity img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.experience .list-activity .item-activity .title-item {
  font-weight: 700;
  font-size: 20px;
  color: #fff !important;
}
.experience .list-activity .item-activity .desc-item {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #fff !important;
}
.experience .list-activity .item-activity .desc-item p ~ p {
  display: none;
}
.experience .list-activity .item-activity .desc-item span ~ span {
  display: none;
}
.experience .list-activity .item-activity .desc-item div ~ div {
  display: none;
}
.experience .item-activity-1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 9;
}
.experience .item-activity-1 .title-item {
  margin-top: 20px;
}
.experience .item-activity-1 img {
  height: 446px !important;
}
.experience .title {
  font-family: "Futura-Medium", sans-serif;
  color: #00e6f0;
  text-align: center;
  font-size: 58px;
  margin-bottom: 25px;
}
.experience .bg-img {
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.experience .particle {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.experience .wrapper-item {
  width: 915px;
  display: flex;
  flex-wrap: wrap;
}
.experience .item {
  text-transform: uppercase;
  margin: 0px 65px 82px 65px;
  width: 175px;
  font-size: 32px;
  font-family: "Futura-Medium", sans-serif;
  text-decoration: none;
  color: #fff;
}

@media (max-width: 767px) {
  .experience .title {
    font-size: 35px;
  }
  .experience .item {
    margin: 0px 0px 32px 0px;
    font-size: 20px;
    width: 100%;
    text-align: center;
  }
  .experience .wrapper-item {
    justify-content: center;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .experience .item {
    width: 102px;
  }
}/*# sourceMappingURL=experience.css.map */
.button-alert {
  width: 136px !important;
  padding: 10px 0px !important;
}

.contact {
  position: relative;
  color: #fff;
  padding: 150px 0px;
  font-family: "Futura-Medium-BT", sans-serif;
  top: 0px;
  background-color: #0e2c4e;
}
.contact .konten {
  position: relative;
  z-index: 2;
}
.contact .title {
  font-family: "Futura-Medium", sans-serif;
  color: #00e6f0;
  text-align: center;
  font-size: 58px;
  margin-bottom: 25px;
}
.contact .required {
  color: #ff441d;
}
.contact .bg {
  position: absolute;
  z-index: 1;
  width: 100%;
}
.contact .bg:nth-child(1) {
  top: 0;
  right: 0;
}
.contact .bg:nth-child(2) {
  bottom: 0;
  left: 0;
  width: 90%;
}
.contact input.form-control {
  height: 45px;
  border-radius: 4px;
}
.contact ::-webkit-input-placeholder {
  /* Edge */
  color: #898989;
  font-size: 14px;
  font-family: "Futura-Light-BT", sans-serif;
}
.contact .form-label {
  font-size: 16px;
  font-weight: 500;
}
.contact textarea {
  height: 80px;
}
.contact .btn-primary {
  border: none;
  font-size: 16px;
  background-color: #26b2c3;
  color: #fff;
  border-radius: 4px;
  height: 48px;
  padding: 0px 30px;
}
.contact .item-alamat {
  font-size: 20px;
  margin-bottom: 25px;
}
.contact .item-alamat .alamat {
  font-family: "Futura-Light", sans-serif;
  line-height: normal;
  font-size: 16px;
}
.contact .item-alamat .lable {
  font-size: 20px;
  font-family: "Futura-Bold", sans-serif;
}

@media (max-width: 767px) {
  .contact {
    padding: 81px 0px;
  }
  .contact .alamat {
    font-size: 16px;
  }
  .contact .btn-primary {
    font-size: 14px;
    width: 100%;
  }
}/*# sourceMappingURL=contact.css.map */
.maps {
  position: relative;
  top: 0;
  height: 44vh;
}
.maps iframe {
  position: relative;
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=maps.css.map */
.family {
  position: relative;
  padding: 116px 0px;
  top: 0px;
  min-height: 100vh;
}
.family .title {
  font-family: "Futura-Medium", sans-serif;
  font-size: 50px;
  right: 30px;
  top: 20px;
  position: absolute;
  color: #0e2c4e;
}
.family .particle:nth-child(1) {
  position: absolute;
  left: 0;
  top: 0;
  width: 40%;
}
.family .particle:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
}
.family .img-family-1 {
  width: 162px;
}
.family .img-family-2 {
  width: 140px;
  height: 60px;
  margin-top: 6px;
  object-fit: contain;
}
.family .img-family-4 {
  width: 140px;
  height: 84px;
  margin-top: 6px;
  object-fit: contain;
}
.family .img-family-3 {
  width: 84px;
  height: 100px;
  object-fit: contain;
  margin-top: 30px;
}
.family .item {
  text-align: center;
}
.family .row-family-3 .item.col-lg-2 {
  width: 127px !important;
}
.family .jawara {
  width: 327px;
}
.family .list-family {
  margin-top: 2rem;
}

@media (max-width: 767px) {
  .family .row-family-3 .item.col-lg-2 {
    width: 50% !important;
  }
  .family .jawara {
    width: 202px;
  }
  .family .img-family-1 {
    width: 162px;
    margin-bottom: 20px;
  }
  .family .img-family-2 {
    margin-bottom: 20px;
    width: 96px;
  }
  .family .img-family-3 {
    height: 65px;
  }
  .family .list-family {
    margin-top: 1rem;
  }
  .family .img-family-4 {
    width: 84px;
    height: 81px;
  }
}
@media only screen and (min-width: 1366px) and (min-height: 1024px) and (orientation: landscape) {
  .family {
    min-height: unset;
    padding: 50px 0px;
  }
}
@media only screen and (min-width: 1024px) and (min-height: 1366px) and (orientation: portrait) {
  .family {
    min-height: unset;
    padding: 50px 0px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .family .img-family-1 {
    width: 205px;
  }
  .family .row-family-3 .item.col-lg-2 {
    width: 16.66666667% !important;
  }
  .timeline .wrapper-particle {
    top: 15%;
  }
  .timeline .particle-timeline-5 {
    bottom: 26%;
  }
}/*# sourceMappingURL=family.css.map */
.timeline {
  position: relative;
  overflow: hidden;
}
.timeline .wrapper-particle {
  position: absolute;
  width: 100%;
  top: 0;
  height: 200vh;
}
.timeline .timeline-mobile {
  width: 100%;
  height: 100%;
}
.timeline .img-asset:nth-child(1) {
  width: 100%;
  z-index: -2;
  height: 100%;
  position: absolute;
}
.timeline .img-asset:nth-child(2) {
  bottom: 0;
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
}
.timeline .img-asset:nth-child(3) {
  bottom: 0;
  right: 0;
  position: absolute;
  width: 45%;
}
.timeline .img-asset:nth-child(4) {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  width: 42%;
}
.timeline .pandemi {
  position: absolute;
  left: 0;
  z-index: 20;
  top: 30%;
  left: 50%;
  width: 7%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.timeline .particle-7 {
  position: absolute;
  left: 0;
  bottom: -20%;
  width: 100%;
  z-index: -1;
}
.timeline .particle-6 {
  position: absolute;
  left: 7%;
  top: 28%;
  width: 20%;
  z-index: -1;
}
.timeline .particle-2 {
  position: absolute;
  right: 0;
  top: 30%;
  width: 20%;
  z-index: 2;
}
.timeline .particle-4 {
  position: absolute;
  right: 0;
  top: 40%;
  width: 13%;
  z-index: 1;
}
.timeline .particle-5 {
  width: 20%;
  top: 45%;
  position: absolute;
  left: 0;
}
.timeline .particle-timeline-1 {
  position: absolute;
  bottom: -17%;
  left: 52%;
  width: 19%;
}
.timeline .particle-timeline-2 {
  position: absolute;
  top: 20%;
  right: 16%;
  width: 27%;
}
.timeline .particle-timeline-3 {
  position: absolute;
  top: 59%;
  right: 23%;
  width: 17%;
}
.timeline .particle-timeline-4 {
  position: absolute;
  top: 26%;
  left: 21%;
  width: 17%;
}
.timeline .particle-timeline-5 {
  position: absolute;
  bottom: 2%;
  left: 20%;
  width: 11%;
}
.timeline .gif {
  z-index: 20;
  position: absolute;
}
.timeline .gif-1 {
  width: 16%;
  bottom: 40%;
  right: 13%;
}
.timeline .gif-2 {
  width: 17%;
  bottom: 50%;
  left: 12%;
}
.timeline .gif-3 {
  width: 30%;
  bottom: 5%;
  right: 15%;
  z-index: 20;
}
.timeline .gif-4 {
  width: 35%;
  top: 3%;
  left: 10%;
  z-index: 20;
}
.timeline .gif-5 {
  width: 17%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 20;
}
.timeline .particle-1 {
  position: absolute;
  left: 0%;
  top: 0;
  z-index: 20;
  width: 200px;
}
.timeline .wrapper-timeline {
  height: 100%;
  position: absolute;
  justify-content: end;
  z-index: 2;
  top: 68%;
  left: 50%;
  width: 70%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.timeline .wrapper-timeline.bottom {
  top: 100%;
}
.timeline .wrapper-timeline .right {
  position: relative;
}
.timeline .wrapper-timeline .right .item-timeline {
  padding-left: 77px;
}
.timeline .wrapper-timeline .left {
  margin-top: 77px;
}
.timeline .wrapper-timeline .left .item-timeline {
  padding-left: 45px;
}
.timeline .wrapper-timeline .list-timeline {
  height: 180vh;
}
.timeline .wrapper-timeline .item-timeline {
  position: absolute;
  margin-bottom: 14px;
}
.timeline .wrapper-timeline .item-timeline .years {
  font-family: "Futura-Bold", sans-serif;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
}
.timeline .wrapper-timeline .item-timeline .events,
.timeline .wrapper-timeline .item-timeline .clients {
  text-transform: uppercase;
  line-height: 12px;
  font-size: 12px;
  color: #fff;
  font-family: "Futura-Medium", sans-serif;
}
.timeline .wrapper-timeline .item-timeline:nth-child(1) {
  right: 10%;
  top: 2%;
}
.timeline .wrapper-timeline .item-timeline:nth-child(2) {
  left: 10%;
  top: 6%;
}
.timeline .wrapper-timeline .item-timeline:nth-child(3) {
  right: 10%;
  top: 9%;
}
.timeline .wrapper-timeline .item-timeline:nth-child(4) {
  left: 10%;
  top: 13%;
}
.timeline .wrapper-timeline .item-timeline:nth-child(5) {
  right: 10%;
  top: 16%;
}
.timeline .wrapper-timeline .item-timeline:nth-child(6) {
  left: 10%;
  top: 20%;
}
.timeline .wrapper-timeline .item-timeline:nth-child(7) {
  right: 10%;
  top: 23%;
}
.timeline .wrapper-timeline .item-timeline:nth-child(8) {
  left: 10%;
  top: 27%;
}
.timeline .wrapper-timeline .item-timeline:nth-child(9) {
  right: 10%;
  top: 32%;
}
.timeline .wrapper-timeline .item-timeline:nth-child(10) {
  left: 10%;
  top: 36%;
}
.timeline .wrapper-timeline .item-timeline:nth-child(11) {
  right: 10%;
  top: 39%;
}
.timeline .wrapper-timeline .item-timeline:nth-child(12) {
  left: 10%;
  top: 43%;
}
.timeline .wrapper-timeline .item-timeline:nth-child(13) {
  right: 10%;
  top: 46%;
}
.timeline .wrapper-timeline .item-timeline:nth-child(14) {
  left: 10%;
  top: 50%;
}
@-webkit-keyframes rotate {
  to {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}
@keyframes rotate {
  to {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}

@media only screen and (max-width: 321px) {
  .timeline .wrapper-timeline .item-timeline .events,
  .timeline .wrapper-timeline .item-timeline .clients {
    font-size: 10px;
  }
  .timeline .wrapper-timeline .item-timeline .years {
    font-size: 16px;
    line-height: 16px;
  }
}/*# sourceMappingURL=timeline.css.map */
.post {
  background-color: #0e2c4e;
  min-height: 100vh;
  padding: 49px 0px;
  position: relative;
  top: 0px;
}
.post.post-page {
  padding: 0px 0px;
  padding-bottom: 30%;
}
.post.post-page .title {
  color: #fff;
}
.post.post-page .img-blog {
  width: 100%;
  height: 201px;
  object-fit: cover;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.post.post-page .list-filter {
  margin-bottom: 20px;
}
.post.post-page .list-filter .title-filter {
  color: #fff;
  font-size: 20px;
  font-family: "Futura-Heavy", sans-serif;
}
.post.post-page .list-filter .item-filter {
  color: #fff;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: "Futura-BK-BT", sans-serif;
  background-color: transparent;
  box-shadow: none;
  border: 0px;
  text-decoration: none;
  font-size: 14px;
}
.post.post-page .list-filter .item-filter.active {
  color: #00e6f0;
}
.post.post-page .list-filter .month .item-filter {
  padding-left: 15px;
}
.post .title {
  font-family: "Futura-Medium", sans-serif;
  color: #00e6f0;
  font-size: 58px;
  margin-bottom: 25px;
}
.post .list-item .item {
  font-family: "Futura-Bold", sans-serif;
  display: block;
  font-size: 28px;
  text-decoration: none;
  color: #fff;
  margin-bottom: 20px;
  text-align: left;
}
.post .list-item .item img {
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.post .list-item .item .title-item {
  font-size: 25px;
  font-weight: bold;
  line-height: normal;
}
.post .list-item .item .kategori-item {
  font-family: "Futura-Medium-Light", sans-serif;
  margin-top: 20px;
  color: #00e6f0;
  font-size: 16px;
}
.post .list-item .item .desc {
  font-size: 19px;
  font-family: "Futura-Book-Font", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
}

@media (max-width: 767px) {
  .post .title {
    text-align: center;
  }
  .post .list-item {
    text-align: center;
  }
  .post .list-item .item {
    font-size: 20px;
  }
  .post .list-item .item .desc {
    font-size: 16px;
  }
}/*# sourceMappingURL=post.css.map */
.news {
  background-color: #fff;
  min-height: 100vh;
  padding: 117px 0px;
  position: relative;
  top: 0px;
}
.news.post-page {
  padding: 0px 0px;
  padding-bottom: 30%;
}
.news.post-page .title {
  color: #fff;
}
.news .title {
  font-family: "Futura-Medium", sans-serif;
  color: #0e2c4e;
  font-size: 58px;
  margin-bottom: 25px;
}
.news .particle:nth-child(1) {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
}
.news .particle:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
}
.news .list-item .item {
  font-family: "Futura-Bold", sans-serif;
  display: block;
  font-size: 28px;
  text-decoration: none;
  color: #fff;
  margin-bottom: 20px;
  cursor: pointer;
}
.news .list-item .item .wrapper-item {
  height: 323px;
  position: relative;
}
.news .list-item .item .wrapper-item .overlay {
  border-radius: 5px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 58.36%, #00a6ad 85.71%), rgba(255, 255, 255, 0) 50%/cover no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.news .list-item .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.news .list-item .item .title-item {
  font-size: 23px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  width: 100%;
  bottom: 20px;
  z-index: 3;
  left: 0;
  padding: 0px 20px;
  font-family: "Futura-Bold", sans-serif;
  text-transform: uppercase;
}
.news .list-item .item .kategori-item {
  font-family: "Futura-Medium-Light", sans-serif;
  margin-top: 20px;
  color: #00e6f0;
  font-size: 16px;
}
.news .list-item .item .desc {
  font-size: 20px;
  font-family: "Futura-Book-Font", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
}

@media (max-width: 767px) {
  .post .title {
    text-align: center;
  }
  .post .list-item {
    text-align: center;
  }
  .post .list-item .item {
    font-size: 20px;
  }
  .post .list-item .item .desc {
    font-size: 16px;
  }
}/*# sourceMappingURL=news.css.map */
.download {
  background-color: #0E2C4E;
  min-height: 100vh;
  position: relative;
  padding: 117px 0px;
}
.download .title {
  font-family: "Futura-Medium", sans-serif;
  color: #00E6F0;
  font-size: 58px;
  margin-bottom: 25px;
}
.download a {
  text-decoration: none;
}
.download .item {
  padding: 20px 0px;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
}
.download .item .icon-download {
  width: 24px;
  height: 24px;
}
.download .item .title-item {
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  margin-left: 16px;
  font-family: "Futura-Bold", sans-serif;
}
.download .item .img-file {
  width: 35px;
}/*# sourceMappingURL=download.css.map */
.footer {
  position: relative;
  top: 0px;
  overflow: hidden;
}
.footer .footer-img {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
}
.footer .bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.footer .gif {
  position: absolute;
  width: 17%;
  z-index: 50;
}
.footer .gif-1 {
  right: 15%;
  width: 15%;
  top: 30%;
}
.footer .gif-2 {
  width: 20%;
  right: 10%;
  bottom: 0;
}
.footer .gif-3 {
  width: 25%;
  left: 10%;
  bottom: 0%;
}
.footer .gif-4 {
  width: 20%;
  left: 10%;
  top: 30%;
}
.footer .gif-5 {
  width: 17%;
  right: 10%;
  top: 50%;
}
.footer .gif-6 {
  right: 30%;
  top: 10%;
  width: 13%;
}
.footer .particle-1 {
  width: 20%;
  position: absolute;
  bottom: -9%;
  right: 10%;
  z-index: -1;
}
.footer .particle-2 {
  width: 20%;
  position: absolute;
  bottom: -21%;
  right: 20%;
  z-index: -1;
}
.footer .particle-3 {
  width: 25%;
  position: absolute;
  top: 49%;
  left: 10%;
  z-index: 2;
}
.footer .particle-4 {
  width: 25%;
  position: absolute;
  top: 33%;
  left: 20%;
  z-index: 2;
}
.footer .particle-5 {
  width: 35%;
  position: absolute;
  top: 36%;
  left: 34%;
  z-index: -1;
}
.footer .particle-6 {
  width: 23%;
  position: absolute;
  top: 43%;
  left: 17%;
  z-index: 1;
}
.footer .particle-7 {
  width: 23%;
  position: absolute;
  top: 46%;
  right: 16%;
  z-index: 1;
}
.footer .particle-8 {
  width: 25%;
  position: absolute;
  bottom: -5%;
  right: 38%;
  z-index: 1;
}
.footer .copyright {
  position: absolute;
  bottom: 2%;
  color: #fff;
  line-height: 13px;
  font-family: "Futura-Bold", sans-serif;
  z-index: 4;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  text-align: center;
  font-size: 9px;
}

@media (device-width: 100vw) and (device-height: 100vh) {
  .footer .particle-8 {
    width: 25%;
    position: absolute;
    bottom: -12% !important;
    right: 38%;
    z-index: 1;
  }
  .footer .particle-1 {
    width: 20%;
    position: absolute;
    bottom: -17% !important;
    right: 10%;
    z-index: -1;
  }
  .footer .particle-2 {
    width: 20%;
    position: absolute;
    bottom: -30% !important;
    right: 20%;
    z-index: -1;
  }
  .footer .particle-3 {
    width: 25%;
    position: absolute;
    top: 56% !important;
    left: 10%;
    z-index: 2;
  }
  .footer .particle-4 {
    width: 25%;
    position: absolute;
    top: 45% !important;
    left: 20%;
    z-index: 2;
  }
  .footer .particle-5 {
    width: 35%;
    position: absolute;
    top: 40% !important;
    left: 34%;
    z-index: -1;
  }
  .footer .particle-6 {
    width: 23%;
    position: absolute;
    top: 50% !important;
    left: 17%;
    z-index: 1;
  }
  .footer .particle-7 {
    width: 23%;
    position: absolute;
    top: 53% !important;
    right: 16%;
    z-index: 1;
  }
  .footer .particle-8 {
    width: 25%;
    position: absolute;
    bottom: -10%;
    right: 38% !important;
    z-index: 1;
  }
}
.footer-mobile {
  position: relative;
  width: 100%;
  height: 100%;
  display: none;
}

@media (max-width: 767px) {
  .footer .copyright {
    font-size: 10px;
  }
  .footer-mobile {
    display: block;
  }
}
@media only screen and (min-width: 1024px) and (orientation: portrait) {
  .footer .wrapper-particle {
    position: absolute;
    width: 100%;
    top: -260%;
    height: 83vh;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) and (orientation: landscape) {
  .footer .wrapper-particle {
    height: 143vh;
    position: absolute;
    top: 60%;
    width: 100%;
    z-index: 1;
  }
  .footer .wrapper-particle .particle-8 {
    bottom: -25%;
  }
  .footer .wrapper-particle .particle-1 {
    bottom: -30%;
  }
  .footer .wrapper-particle .particle-2 {
    bottom: -46%;
  }
  .footer .wrapper-particle .particle-4 {
    top: 38%;
  }
  .footer .wrapper-particle .particle-3 {
    top: 52%;
  }
}/*# sourceMappingURL=footer.css.map */
@font-face {
  font-family: "Futura-Bold";
  font-display: auto;
  src: url("/static/media/Futura BdCn BT Bold.d2562d88.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Futura-Medium";
  src: url("/static/media/Futura MdCn BT Medium.1a89f963.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Futura-Medium-light";
  src: url("/static/media/Futura Md BT Medium.4f0900bd.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Futura-Medium-BT";
  src: url("/static/media/Futura MdCn BT Medium.1a89f963.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Futura-Light-BT";
  src: url("/static/media/Futura Lt BT Light.53b7f155.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Futura-Book-Font";
  src: url("/static/media/Futura Book.0e63907a.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Futura-Heavy-Italic";
  src: url("/static/media/Futura Hv BT Heavy Italic.3ac2d2aa.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Futura-Heavy";
  src: url("/static/media/Futura Heavy.e3a3f54b.ttf") format("ttf");
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Futura-Light";
  src: url("/static/media/Futura Condensed Light.25cb93c1.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Futura-BK-BT";
  src: url(/static/media/FuturaBookBT.c583d7db.ttf) format("ttf");
  font-display: swap;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e2e2e2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.loader {
  align-items: center;
  justify-content: center;
  height: 100vh;
  display: flex;
  background-color: #0e2c4e;
}

@media (max-width: 767px) {
  .title {
    font-size: 30px !important;
  }
  .container {
    padding: 0px 34px;
  }
}
.btn-more {
  color: #0e2c4e;
  font-size: 14px;
  width: 100px;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
}
.btn-more:hover {
  background-color: #fff;
  color: #0e2c4e;
}

.btn-more-blue {
  color: #fff;
  font-size: 14px;
  width: 100px;
  padding: 10px;
  background-color: #0e2c4e;
  cursor: pointer;
}
.btn-more-blue:hover {
  background-color: #0e2c4e;
  color: #fff;
}

.post-page {
  position: relative;
  min-height: 100vh;
  padding-bottom: 30%;
  background: linear-gradient(180deg, #0E2C4E 58.33%, #0373A3 98.59%);
}
.post-page .title-section {
  font-size: 27px !important;
  color: #fff;
  margin-bottom: 0px;
}
.post-page.news .img-news {
  width: 100%;
}

.post-page .header .nav .logo,
.post-page .header .nav .sosmed {
  display: block;
}

.active > .page-link, .page-link.active {
  z-index: 0;
}

label > input[type=radio],
label > input[type=radio] {
  display: none !important;
}

label > input[type=radio] + *::before,
label > input[type=radio] + *::before {
  content: "" !important;
  display: inline-block !important;
  vertical-align: bottom !important;
  width: 1.1rem !important;
  height: 1.1rem !important;
  margin-right: 0.3rem !important;
  border-radius: 50% !important;
  border-style: solid;
  border-width: 0.1rem;
  border-color: #eceff1 !important;
}

label > input[type=radio]:checked + *::before,
label > input[type=radio]:checked + *::before {
  background: radial-gradient(#26B2C3 0%, #26B2C3 40%, transparent 50%, transparent) !important;
  border-color: #26B2C3 !important;
}

input[type=checkbox] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
       appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  margin-right: 10px;
  font: inherit;
  color: currentColor;
  width: 22px;
  height: 22px;
  border: 1px solid #d0d0d0;
  border-radius: 0.15em;
  -webkit-transform: translateY(-0.075em);
          transform: translateY(-0.075em);
  padding: 0px !important;
  display: grid;
  place-content: center;
}

input[type=checkbox]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms transform ease-in-out;
  transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #fff;
}

input[type=checkbox]:checked::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}

input[type=checkbox]:checked {
  background-color: #26B2C3 !important;
  border: #26B2C3 solid 2px;
}

.btn-primary {
  border: none;
  font-size: 16px;
  background-color: #26b2c3;
  color: #fff;
  border-radius: 4px;
  height: 48px;
  padding: 0px 30px;
}/*# sourceMappingURL=home.css.map */
.post-page .header .nav .logo,
.post-page .header .nav .sosmed {
  display: block;
}
.post-page .main-content {
  padding-top: 70px;
  padding-bottom: 90px;
}
.post-page .main-content .more-portofolio .line {
  width: 37.5px;
  height: 1px;
  background-color: #fff;
  margin-right: 10px;
}
.post-page .main-content .more-portofolio .item-activity {
  color: #fff !important;
  margin-bottom: 30px;
}
.post-page .main-content .more-portofolio .item-activity a {
  text-decoration: none;
}
.post-page .main-content .more-portofolio .item-activity.item-right img {
  width: 223px;
  height: 153px;
}
.post-page .main-content .more-portofolio .item-activity img {
  width: 100%;
  object-fit: cover;
  height: 250px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.post-page .main-content .more-portofolio .item-activity .title-item {
  font-weight: 700;
  font-size: 20px;
  color: #fff !important;
  margin-top: 15px;
}
.post-page .main-content .more-portofolio .item-activity .desc-item {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #fff !important;
  font-family: "Futura-BK-BT", sans-serif;
}
.post-page .main-content .more-portofolio .item-activity .desc-item p ~ p {
  display: none;
}
.post-page .main-content .more-portofolio .item-activity .desc-item span ~ span {
  display: none;
}
.post-page .main-content .more-portofolio .item-activity .desc-item div ~ div {
  display: none;
}
.post-page .main-content .more-portofolio .item {
  color: white !important;
  text-decoration: none;
}
.post-page .main-content .more-portofolio .desc {
  height: 100px;
}

.copyright {
  position: absolute;
  bottom: 0%;
  color: #fff;
  font-family: "Futura-Bold", sans-serif;
  z-index: 4;
  left: 50%;
  width: 100%;
  font-size: 10px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.carousel {
  margin-bottom: 10%;
  margin-top: 7%;
}

.main-content {
  color: #fff;
  padding: 177px 0px;
}
.main-content .more-portofolio .item {
  color: white !important;
  text-decoration: none;
}
.main-content .more-portofolio .desc {
  height: 100px;
}
.main-content .date {
  font-family: "Futura-Heavy-Italic", sans-serif;
}
.main-content .title {
  font-family: "Futura-Bold", sans-serif;
  font-size: 30px;
}
.main-content .desc {
  font-family: "Futura-Book-Font", sans-serif;
  line-height: 30px;
}
.main-content .content {
  position: relative;
  z-index: 2;
}

.product--image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 100%;
  border-radius: 0px;
  border: none;
  height: 241px;
  margin: auto 0px;
  text-align: center;
  font-family: arial;
}
.card img {
  cursor: pointer;
}

.img-bottom {
  bottom: 0;
  width: 100%;
  z-index: 1;
  position: absolute;
}

.logo-footer {
  width: 129px;
  position: absolute;
  bottom: 100px;
  left: 50%;
  z-index: 4;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.img-top {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

@media (max-width: 767px) {
  .main-content {
    padding: 122px 0px;
  }
}
@media (max-width: 992px) {
  .post-page .header .nav .sosmed {
    display: none;
  }
}/*# sourceMappingURL=portofolio.css.map */
.freelance .line {
  background-color: #fff;
  height: 1px;
  width: 100%;
  margin: 20px 0px;
}
.freelance .css-13cymwt-control {
  min-height: 55px;
}
.freelance input.form-control {
  padding: 15px 0.75rem;
  min-height: 55px;
}
.freelance .title-form {
  font-family: "Futura-Medium-light", sans-serif;
  font-size: 24px;
}
.freelance .subtitle-form {
  font-family: "Futura-Light-BT", sans-serif;
  font-size: 20px;
  margin: 5px 0px;
}
.freelance .form-group {
  margin-bottom: 20px;
}
.freelance .form-group label {
  font-family: "Futura-Medium-light", sans-serif;
  font-size: 16px;
  margin-bottom: 8px;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block !important;
}/*# sourceMappingURL=freelance.css.map */
